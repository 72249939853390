import React from 'react';
export default function BreadCrumbs({ crumbs }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <nav
            role="navigation"
            aria-label="Breadcrumb"
            className="d-none d-lg-block"
          >
            <ol className="breadcrumb">
              {crumbs.map(crumb => (
                <li key={crumb.label} className="breadcrumb-item">
                  <a href={crumb.route}>{crumb.label}</a>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
}
