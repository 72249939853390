import React from 'react';
import { phone, email } from './assets/img/general_use';
import { ext } from './assets/img/govd';
import {
  cdctv,
  fb,
  ig,
  rss,
  synd,
  twitter,
  youtube,
} from './assets/img/white_social_media';

export default () => (
  <footer role="contentinfo" aria-label="Footer">
    <div className="container-fluid footer-wrapper">
      <div className="container">
        {/* <!-- Begin included content (from https://www.cdc.gov/TemplatePackage/4.0/includes/footer-cdc.html) --> */}
        <div className="row">
          <address
            className="col-md-3"
            itemScope="itemScope"
            itemType="https://schema.org/Organization"
          >
            <strong>CONTACT CDC-INFO</strong>
            <div className="row mt-2">
              <div className="col-1">
                <img alt="phone" className="icon" src={phone} />
              </div>
              <div className="col">
                <span>Have questions? We have answers.</span>
                <span itemProp="telephone">1-800-CDC-INFO (800-232-4636)</span>
                <span itemProp="telephone">TTY: 888-232-6348</span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-1">
                <img alt="email" className="icon" src={email} />
              </div>
              <div
                className="col"
                itemProp="contactPoint"
                itemScope="itemScope"
                itemType="https://schema.org/ContactPoint"
              >
                <span itemProp="url">
                  <a href="https://wwwn.cdc.gov/dcs/RequestForm.aspx">
                    Email CDC-INFO
                  </a>
                </span>
              </div>
            </div>
          </address>
          <div className="col-md-3 mt-3 mt-md-0">
            <strong>CDC INFORMATION</strong>
            <ul>
              <li>
                <a href="https://www.cdc.gov/about/default.htm">About CDC</a>
              </li>
              <li>
                <a href="https://jobs.cdc.gov">Jobs</a>
              </li>
              <li>
                <a href="https://www.cdc.gov/funding">Funding</a>
              </li>
              <li>
                <a href="https://www.cdc.gov/Other/policies.html">Policies</a>
              </li>
              <li>
                <a href="https://www.cdc.gov/other/plugins/index.html">
                  File Viewers &amp; Players
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <li>
                <a href="https://www.cdc.gov/Other/policies.html">Privacy</a>
              </li>
              <li>
                <a href="https://www.cdc.gov/od/foia">FOIA</a>
              </li>
              <li>
                <a href="https://www.cdc.gov/eeo/nofearact/index.htm">
                  No Fear Act
                </a>
              </li>
              <li>
                <a href="https://oig.hhs.gov">OIG</a>
              </li>
              <li>
                <a href="https://www.cdc.gov/other/nondiscrimination.html">
                  Nondiscrimination
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/contact/accessibility.html">
                  Accessibility
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 mt-2 mt-md-0 connect-icons">
            <strong>CONNECT WITH CDC</strong>
            <br />
            <ul className="list-inline mt-3">
              <li>
                <a
                  href="https://www.facebook.com/CDC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="facebook" className="icon" src={fb} />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/CDCgov"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="twitter" className="icon" src={twitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/CDCstreamingHealth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="youtube" className="icon" src={youtube} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/CDCgov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="instagram" className="icon" src={ig} />
                </a>
              </li>
            </ul>
            <br className="d-none d-md-block" />
            <ul className="list-inline mt-2">
              <li>
                <a href="#" className="footer-syndlink">
                  <img alt="syndicate" className="icon" src={synd} />
                </a>
              </li>
              <li>
                <a href="https://www.cdc.gov/cdctv">
                  <img alt="cdc tv" className="icon" src={cdctv} />
                </a>
              </li>
              <li>
                <a href="https://tools.cdc.gov/podcasts/rss.asp">
                  <img alt="rss" className="icon" src={rss} />
                </a>
              </li>
              <li>
                <a href="https://wwwn.cdc.gov/dcs/RequestForm.aspx">
                  <img alt="email" className="icon" src={email} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- End included content (from https://www.cdc.gov/TemplatePackage/4.0/includes/footer-cdc.html) --> */}
      </div>
    </div>

    {/* <!-- (22) Sub-Footer --> */}
    <div className="container-fluid agency-footer">
      <div className="container">
        {/* <!-- Begin included content (from https://www.cdc.gov/TemplatePackage/4.0/includes/footer-agency.html) --> */}
        <div
          className="row"
          itemScope="itemScope"
          itemType="https://schema.org/Organization"
        >
          <div className="col-md-6 mt-1 mt-md-0">
            <span
              className="value"
              itemProp="contactPoint"
              itemScope="itemScope"
              itemType="https://schema.org/ContactPoint"
            >
              <span itemProp="url">
                <a href="https://www.hhs.gov">
                  U.S. Department of Health &amp; Human Services
                </a>
              </span>
            </span>
          </div>
          <div className="col-md-3 mt-1 mt-md-0">
            <span
              className="value"
              itemProp="contactPoint"
              itemScope="itemScope"
              itemType="https://schema.org/ContactPoint"
            >
              <span itemProp="url">
                <a href="https://www.usa.gov/">USA.gov</a>
              </span>
            </span>
          </div>
          <div className="col-md-3 mt-1 mt-md-0">
            <span className="value">
              <span>
                <a
                  href="https://www.cdc.gov/Other/disclaimer.html"
                  className="footer-ext"
                >
                  CDC Website Exit Disclaimer
                </a>
                <img alt="exit" className="icon x16" src={ext} />
              </span>
            </span>
          </div>
        </div>
        {/* <!-- End included content (from https://www.cdc.gov/TemplatePackage/4.0/includes/footer-agency.html) --> */}
      </div>
    </div>
  </footer>
);
