import { format, env } from '../../utils/helpers';
import { ExportToCsv } from 'export-to-csv';

export function formatTopics({ topics, questions, comparisondata }) {
  const memo = {
    /*
     * [topicId]: {
     * displayOrder: number
     * description: string
     * questions : {
     *   [questionId]: {
     *       displayOrder: number
     *       description: number
     *       year: number
     *       dataType: {
     *            value: boolean,
     *            ci: boolean,
     *            sampleSize: boolean
     *          }
     *       locations: {
     *            [locationId]: {
     *                      value: string @TODO add footnotes symbol
     *                      ci: [number, number] *low and high @TODO change to string when data changes
     *                      sampleSize: number
     *                }
     *          }
     *     }
     *   }
     * },
     */
  };

  topics.forEach((topic) => {
    memo[topic.Id] = {
      displayOrder: topic.DisplayOrder,
      description: topic.Description,
      questions: {},
      classId: topic.ClassId,
    };
  });

  comparisondata.forEach((datum) => {
    const {
      TopicId,
      QuestionId,
      YearStart,
      YearEnd,
      DataValueTypeId,
      Data_Value,
      Low_Confidence_Limit,
      High_Confidence_Limit,
      Sample_Size,
      LocationId,
      Data_Value_Footnote_Symbol,
    } = datum;
    if (!memo[TopicId].questions[QuestionId]) {
      const question = questions.find((q) => q.Id === QuestionId);
      memo[TopicId].questions[QuestionId] = {
        displayOrder: question.DisplayOrder,
        description: question.Description,
        dataSourceId: question.DataSourceId,
        yearStart: YearStart,
        yearEnd: YearEnd,
        dataType: {
          value: !!DataValueTypeId,
          ci: !!Low_Confidence_Limit,
          sampleSize: !!Sample_Size,
        },
        locations: {},
      };
    }
    function withZero(num) {
      if (!num) return;
      if (num % 1 === 0) {
        return `${num}.0`;
      }
      return num.toString();
    }
    memo[TopicId].questions[QuestionId].locations[LocationId] = {
      value: Data_Value,
      ci: [withZero(Low_Confidence_Limit), withZero(High_Confidence_Limit)],
      sampleSize: format.comma(Sample_Size),
      footnoteSymbol: Data_Value_Footnote_Symbol,
    };

    // check each and see if it has CI, value, and sampleSize
    // and update if the datum does but the memo does not.
    const { value, ci, sampleSize } = memo[TopicId].questions[
      QuestionId
    ].dataType;
    if (!value && !!Data_Value)
      memo[TopicId].questions[QuestionId].dataType.value = true;
    if (!ci && !!Low_Confidence_Limit)
      memo[TopicId].questions[QuestionId].dataType.ci = true;
    if (!sampleSize && !!Sample_Size)
      memo[TopicId].questions[QuestionId].dataType.sampleSize = true;
  });

  return memo;
}

function nullToString(value) {
  if (value === null || value === undefined) return '';
  return value;
}

export function csvExport({ topics, metadata }) {
  const csvData = [];
  Object.keys(topics).forEach((topicid) => {
    // Current Iteration Data
    const topicData = topics[topicid];
    const Topic = topicData.description;
    Object.keys(topicData.questions).forEach((questionid) => {
      const questionData = topicData.questions[questionid];
      const YearStart = questionData.yearStart;
      const YearEnd = questionData.yearEnd;
      const Question = questionData.description;
      const DataSource = questionData.dataSourceId;
      Object.keys(questionData.locations).forEach((locationid) => {
        // Location Meta Data lookup
        const locationMeta = metadata.Location.find(
          (loc) => loc.LocationId === locationid
        );
        const LocationAbbr = locationMeta.LocationAbbr;
        const LocationDesc = locationMeta.Description;
        // Current Iteration Data
        const locationData = questionData.locations[locationid];
        // Footnote Meta Data Lookup
        const Data_Value_Footnote_Symbol = nullToString(
          locationData.footnoteSymbol
        );
        const footnoteMeta = metadata.DataValueFootnote.find(
          (ftnt) => ftnt.FootnoteSymbol === Data_Value_Footnote_Symbol
        );
        const Data_Value_Footnote = footnoteMeta
          ? footnoteMeta.FootnoteText
          : '';
        const [
          Low_Confidence_Limit,
          High_Confidence_Limit,
        ] = locationData.ci.map((val) => nullToString(val));
        const Sample_Size = locationData.sampleSize;
        const Data_Value = locationData.value;
        csvData.push({
          YearStart,
          YearEnd,
          LocationAbbr,
          LocationDesc,
          DataSource,
          Topic,
          Question,
          StratficationCategory1: 'Total',
          Stratification1: 'Total',
          Data_Value_Type: 'Value',
          Data_Value: nullToString(Data_Value),
          Data_Value_Footnote_Symbol: nullToString(Data_Value_Footnote_Symbol),
          Data_Value_Footnote,
          Low_Confidence_Limit,
          High_Confidence_Limit,
          Sample_Size,
          FootnoteText: '',
          URL: '',
          FootnoteType: '',
        });
      });
    });
  });
  const metaRows = {
    Datasource: [],
    Global: [],
    SuggestedCitation: [],
    RelatedLinks: [],
  };
  metadata.Footnote.forEach(
    ({ FootnoteText, FootnoteType, URL, DataSource }) => {
      const dataSource = FootnoteType === 'Global' ? `${DataSource} - ` : '';
      metaRows[FootnoteType].push({
        YearStart: '',
        YearEnd: '',
        LocationAbbr: '',
        LocationDesc: '',
        DataSource: '',
        Topic: '',
        Question: '',
        StratficationCategory1: '',
        Stratification1: '',
        Data_Value_Type: '',
        Data_Value: '',
        Data_Value_Footnote_Symbol: '',
        Data_Value_Footnote: '',
        Low_Confidence_Limit: '',
        High_Confidence_Limit: '',
        Sample_Size: '',
        FootnoteText: dataSource + FootnoteText,
        URL: URL || '',
        FootnoteType,
      });
    }
  );
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'DNPAO Comparison Report',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv([
    ...csvData,
    ...metaRows.Datasource,
    ...metaRows.Global,
    ...metaRows.SuggestedCitation,
    ...metaRows.RelatedLinks,
  ]);
}
