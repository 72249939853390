function toJSON(response) {
  // why do this: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
  if (!response.ok) {
    const { statusText, status } = response;
    throw Error(statusText || status);
  }
  return response.json();
}

const API = (process.env.REACT_APP_DNPAO_PATH || '') + '/api';

export function getMetadata() {
  return fetch(API + '/metadata').then(toJSON);
}

export function getComparisondata({ locationIds }) {
  const locs = locationIds.join(',');
  return fetch(`${API}/comparisondata?locationIds=${locs}`).then(toJSON);
}
