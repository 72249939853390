import React, { useState, useRef, useEffect } from 'react';
import { keyUpWrapper } from '../../utils/helpers';
import Modal from 'react-modal';
import CheckBox from '../CheckBox';
import './style.scss';

// this helps with Accessibility by setting the aria-hidden to true on
// the main element when the modal is open.
// https://reactcommunity.org/react-modal/examples/set_app_element/
Modal.setAppElement('#root');

export default function LocationsModal({
  locations,
  locationIds,
  setLocationIds,
  setAllOpen,
}) {
  const [modalIsOpen, openModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState(locationIds);
  const updateCheckedItems = (locationId, isChecked) => {
    if (isChecked) {
      // if v (value) === true it is checked
      // and they can only select 5 items
      if (checkedItems.length === 5) return;
      setCheckedItems([...checkedItems, locationId]);
    } else {
      // remove it
      setCheckedItems(checkedItems.filter((id) => id !== locationId));
    }
  };
  function getSortedLocations() {
    let sorted = [];
    checkedItems.forEach((locationId) => {
      const location = locations.find((loc) => loc.LocationId === locationId);
      sorted[location.DisplayOrder] = locationId;
    });
    return sorted.filter((val) => val !== undefined);
  }
  function handleSubmit() {
    if (!checkedItems.length) return;
    const sortedLocations = getSortedLocations();
    // Set the location Ids in the App.js
    setLocationIds(sortedLocations);
    // close Modal
    openModal(false);
    // set first topic to an open state by passing undefined
    //setAllOpen(undefined);
  }
  function handleChange(e) {
    const { value, checked } = e.target;
    updateCheckedItems(value, checked);
  }
  const loc = {
    National: [],
    States: [],
    Territories: [],
  };
  locations.forEach(
    ({ LocationId, Description, LocationType, LocationAbbr }) => {
      loc[LocationType].push(
        <CheckBox
          key={LocationAbbr}
          {...{
            value: LocationId,
            label: Description,
            id: LocationAbbr,
            handleChange,
            checked: checkedItems.includes(LocationId),
          }}
        />
      );
    }
  );

  function calcStyle() {
    const width = 400;
    const fromScreenEdge = `${window.innerWidth / 2 - width / 2}px`;
    const topBottom = 40; // 40px is the default set by React Modal
    const height = `${window.innerHeight - topBottom * 2}px`;
    return {
      left: fromScreenEdge,
      right: fromScreenEdge,
      height,
      padding: 0,
      overflow: 'visible',
    };
  }
  const contentRef = useRef(null);
  useEffect(() => {
    // this will handle repositioning the Modal
    // when the user resizes the browser.
    function setSize() {
      if (modalIsOpen) {
        const { left, right, height } = calcStyle();
        contentRef.current.style.left = left;
        contentRef.current.style.right = right;
        contentRef.current.style.height = height;
      }
    }
    window.addEventListener('resize', setSize);
    if (modalIsOpen) {
      // because the location modal remains "mounted"
      // even though it has been close. The "state" of the component
      // persists. So we need to reset the "checked items"
      // back to what was passed down from the parent which are the
      // "locationIds"
      setCheckedItems(locationIds);
    }
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [modalIsOpen]);

  const scroll = {
    // this disables/enables the scrolling behavior
    // on the root element only so that the scroll
    // inside the modal is the only one available
    disable: () => {
      document.querySelector('#root').style.position = 'fixed';
      document.querySelector('#root').style.width = '100%';
    },
    enable: () => {
      document.querySelector('#root').style.position = 'relative';
      document.querySelector('#root').style.width = 'auto';
    },
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          openModal(true);
        }}
      >
        Add/Remove Locations
      </button>
      <Modal
        isOpen={modalIsOpen}
        parentSelector={() => document.querySelector('#root')}
        style={{ content: calcStyle() }}
        contentRef={(node) => (contentRef.current = node)}
        onAfterOpen={scroll.disable}
        onAfterClose={scroll.enable}
      >
        <div className="bg-primary p-3">Select Locations</div>
        <p className="px-3 m-0">Select up to 5 locations</p>
        <p className="px-3 m-0">
          ({checkedItems.length} of 5 selected)
          <span
            role="button"
            tabIndex="0"
            className="modal__clear link"
            onClick={() => setCheckedItems([])}
            onKeyUp={(e) => keyUpWrapper(e, setCheckedItems, [])}
          >
            Clear All
          </span>
        </p>
        <div className="modal__form px-3">
          <div className="modal__checks border p-2">
            <p className="font-weight-bold mb-0 mt-1">National</p>
            {loc.National}
            <p className="font-weight-bold mb-0 mt-1">States</p>
            {loc.States}
            <p className="font-weight-bold mb-0 mt-1">Territories</p>
            {loc.Territories}
          </div>
          <div className="d-flex justify-content-center p-2">
            <button
              className="btn btn-primary mx-1"
              onClick={handleSubmit}
              onKeyUp={(e) => keyUpWrapper(e, handleSubmit)}
              disabled={!checkedItems.length}
            >
              Submit
            </button>
            <button
              className="btn btn-light mx-1"
              onClick={() => openModal(false)}
              onKeyUp={(e) => keyUpWrapper(e, openModal, false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
