import React, { useEffect } from 'react';
import { keyUpWrapper, env } from '../../utils/helpers';
import './style.scss';

function SearchedText({ text, searchTerm }) {
  if (!searchTerm) return text;
  // find the indexes of the term within the string.
  const match = text.match(new RegExp(searchTerm, 'i'));
  if (!match) return text; // make it recursive so that everything is highlighted
  // split the string into 3 parts
  const termStart = match.index;
  const termEnd = match.index + searchTerm.length;
  const firstPart = text.slice(0, termStart);
  const searchedTerm = text.slice(termStart, termEnd);
  const lastPart = text.slice(termEnd, text.length);
  // wrap the matching term in a span with a yellow background
  return (
    <>
      {firstPart}
      <span style={{ background: 'yellow' }}>{searchedTerm}</span>
      <SearchedText text={lastPart} searchTerm={searchTerm} />
    </>
  );
}

export default function Table({
  displayOrder,
  description,
  topicId,
  questions,
  locations,
  open,
  searchTerm,
  options,
  setLocationIds,
  classId,
  handleClick,
}) {
  function removeLocation(locationId) {
    let locIds = [];
    locations.forEach((loc) => {
      if (loc.LocationId !== locationId) locIds.push(loc.LocationId);
    });
    setLocationIds(locIds);
  }

  useEffect(() => {
    const plusMinusEl = document.getElementById('plusMinusIcon' + topicId);
    const tableEl = document.getElementById(topicId + 'wrapper');
    if (open) {
      plusMinusEl.className = 'fi cdc-icon-minus topic-collapse__plusminus';
      plusMinusEl.style.transform = 'rotate(-180deg)';
      tableEl.style.display = 'block';
    } else {
      plusMinusEl.className = 'fi cdc-icon-plus topic-collapse__plusminus';
      plusMinusEl.style.transform = 'rotate(0deg)';
      tableEl.style.display = 'none';
    }
  }, [open]);
  return (
    <div>
      <div
        className="topic-collapse"
        role="button"
        tabIndex="0"
        labelfor={topicId}
        onClick={() => handleClick()}
        onKeyUp={(e) => keyUpWrapper(e, handleClick)}
      >
        <span
          id={'plusMinusIcon' + topicId}
          className="fi cdc-icon-plus topic-collapse__plusminus"
          aria-hidden="true"
        ></span>
        {description}
      </div>
      <div id={topicId + 'wrapper'} className="topic-table__wrapper">
        <table className="table topic-table" id={topicId}>
          <caption className="d-none">
            This table contains data for all indicators in the topic{' '}
            {description} and contains column headers for the Indicator, the
            Data Type, and for the selected location(s):{' '}
            {locations.map((loc) => loc.Description).join(', ')}.
          </caption>
          <colgroup>
            <col />
            <col />
            {locations.map((loc) => (
              <col key={loc.LocationAbbr} style={{ width: '15%' }} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Indicator</th>
              <th scope="col" className="text-center">
                Data Type
              </th>
              {locations.map((location) => {
                return (
                  <th
                    scope="col"
                    key={location.LocationAbbr}
                    className="text-center"
                  >
                    {location.Description}
                    <br />
                    {locations.length > 1 && (
                      <span
                        className="link"
                        role="button"
                        tabIndex="0"
                        onClick={() => removeLocation(location.LocationId)}
                        onKeyUp={(e) => {
                          e.key === 'Enter' &&
                            removeLocation(location.LocationId);
                        }}
                      >
                        Remove
                      </span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Object.keys(questions).map((questionId) => {
              const {
                displayOrder,
                description,
                yearStart,
                yearEnd,
                dataType,
              } = questions[questionId];
              // locations which are given for each question, Object w/ locationId as key
              const questionLocs = questions[questionId].locations;
              if (!dataType.value && options.missingData) return null;
              return (
                <tr key={questionId}>
                  <th scope="row">
                    <SearchedText text={description} searchTerm={searchTerm} />
                    <br />
                    {yearStart === yearEnd
                      ? yearEnd
                      : `${yearStart} - ${yearEnd}`}
                    <br />
                    <a
                      href={
                        env('cdc') +
                        `/nccdphp/dnpao/data-trends-maps/help/npao_dtm/definitions.html#${topicId}-${questionId}`
                      }
                    >
                      View Definition
                    </a>{' '}
                    |{' '}
                    <a
                      href={
                        env('nccd') +
                        `/dnpao_dtm/rdPage.aspx?rdReport=DNPAO_DTM.ExploreByTopic&islClass=${classId}&islTopic=${topicId}&islIndicators=${questionId}&go=GO`
                      }
                    >
                      View All Locations
                    </a>
                  </th>
                  <td className="text-center">
                    {dataType.value && 'Value'}
                    <br />
                    {dataType.ci && options.ci && '(95% CI)'}
                    <br />
                    {dataType.sampleSize &&
                      options.weightedNum &&
                      'Sample Size'}
                  </td>
                  {locations.map(({ LocationId }) => {
                    const [lowCI, highCI] = questionLocs[LocationId]
                      ? questionLocs[LocationId].ci
                      : [];
                    if (!questionLocs[LocationId]) {
                      return (
                        <td className="text-center" key={LocationId}>
                          -
                        </td>
                      );
                    }
                    if (questionLocs[LocationId].footnoteSymbol) {
                      return (
                        <td className="text-center" key={LocationId}>
                          {questionLocs[LocationId].footnoteSymbol}
                        </td>
                      );
                    }
                    return (
                      <td className="text-center" key={LocationId}>
                        {questionLocs[LocationId].value}
                        <br />
                        {dataType.ci &&
                          options.ci &&
                          !!lowCI &&
                          `(${lowCI} - ${highCI})`}
                        <br />
                        {dataType.sampleSize &&
                          options.weightedNum &&
                          questionLocs[LocationId].sampleSize}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
