import React from 'react';
import './style.scss';

// Datasource
// GlobalNotes
// Suggestedcitation
// RelatedLinks

export default function Footnotes({ DataValueFootnote, Footnote }) {
  const dataValueNotes = [...DataValueFootnote].sort(
    (a, b) => a.DisplayOrder - b.DisplayOrder
  );
  const dataSourceLookup = {}; // provides full text for datasource acronyms
  const notes = {
    Datasource: [],
    Global: [],
    SuggestedCitation: [],
    RelatedLinks: [],
  };
  Footnote.forEach(({ DataSource, FootnoteText, URL, FootnoteType }) => {
    if (FootnoteType === 'Datasource') {
      dataSourceLookup[DataSource] = FootnoteText;
    }
    notes[FootnoteType].push({ DataSource, FootnoteText, URL });
  });
  return (
    <div className="footnotes">
      <ul>
        {/* DataValueFootnotes*/}
        {dataValueNotes.map(({ FootnoteSymbol, FootnoteText }) => (
          <li key={FootnoteSymbol}>
            {FootnoteSymbol} {FootnoteText}
          </li>
        ))}
      </ul>
      <ul>
        <li>
          <h5>Data Source</h5>
          <ul>
            {notes.Datasource.map(({ DataSource, FootnoteText, URL }) => (
              <li key={URL}>
                <a href={URL}>{`${FootnoteText} (${DataSource})`}</a>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <h5>Global Footnotes</h5>
          <ul>
            {notes.Global.map(({ DataSource, FootnoteText }, i) => (
              <li key={DataSource + i}>
                <p className="p-0 m-0" style={{ fontWeight: 'bold' }}>
                  {dataSourceLookup[DataSource]} ({DataSource})
                </p>
                {FootnoteText}
              </li>
            ))}
          </ul>
        </li>
        <li>
          <h5>Suggested Citation</h5>
          <ul>
            {notes.SuggestedCitation.map(({ FootnoteText, URL }, i) => (
              <li key={i}>
                <a href={URL}>{FootnoteText}</a>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <h5>Related Links</h5>
          <ul>
            {notes.RelatedLinks.map(({ FootnoteText, URL }, i) => (
              <li key={URL}>
                <a href={URL}>{FootnoteText}</a>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}
