import {
  doInteraction,
  doAnalytics,
} from '../modules/tp4/assets/js/doAnalytics';

export function pageLoad() {
  doAnalytics({
    levels: ['Chronic', 'DNPAO', 'DNPAO Apps'],
    channel: 'DNPAO DTM Open Data',
  });
}

// User clicks Save on Add/Remove modal
export function changeLocation({ locationIds }) {
  doInteraction(`DNPAO DTM-CRL ${locationIds.join('-')}`);
}

// User clicks Update in the Options menu
export function changeOptions({ ci, weightedNum, missingData }) {
  const showCI = ci ? 'C' : 'U';
  const showWN = weightedNum ? 'C' : 'U';
  const showMD = missingData ? 'C' : 'U';
  doInteraction(`DNPAO DTM-CRO ${showCI},${showWN},${showMD}`);
}

// User clicks Save as CSV
export function saveAsCSV({ locationIds }) {
  doInteraction(`DNPAO DTM-CRE ${locationIds.join('-')},CSV`);
}
