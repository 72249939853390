import React, { useState } from 'react';
import { keyUpWrapper } from '../../utils/helpers';

export default function Search({
  setSearchTerm,
  setAllOpen,
  searchCount,
  setSearchCount,
}) {
  let [searchString, setSearchString] = useState('');
  function handleSubmit(e) {
    e.preventDefault();
    if (!searchString) {
      handleClear();
      return;
    }
    setAllOpen(undefined);
    setSearchTerm(searchString);
  }
  function handleChange(e) {
    const { value } = e.target;
    setSearchString(value);
  }
  function handleClear() {
    setSearchString('');
    setSearchTerm('');
    setSearchCount(undefined);
  }
  return (
    <form onSubmit={handleSubmit}>
      <label className="px-1" htmlFor="searchInput">
        Search Report
      </label>
      <input
        id="searchInput"
        type="text"
        onChange={handleChange}
        value={searchString}
      ></input>
      <input type="submit" value="Search"></input>
      <span
        tabIndex="0"
        role="button"
        className="link px-1"
        onClick={handleClear}
        onKeyUp={(e) => keyUpWrapper(e, handleClear)}
      >
        Clear
      </span>
      {searchCount !== undefined && (
        <span style={{ background: 'yellow', whiteSpace: 'nowrap' }}>
          {searchCount} Results
        </span>
      )}
    </form>
  );
}
