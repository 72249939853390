import React from 'react';

export default ({ title }) => (
  <div className="container-fluid site-title">
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="display-6 text-white fw-500 pt-1 pb-1">{title}</div>
        </div>
      </div>
    </div>
  </div>
);
