export function getQueryParam($key) {
  let query = window.location.href.split('?')[1];
  if (!query) return '';
  const keyValues = query.split('&');
  let found = '';
  for (let i = 0; i < keyValues.length; i++) {
    const curr = keyValues[i].split('=');
    if (curr[0] === $key) {
      found = curr[1];
      break;
    }
  }
  return found.replace(/%20/g, ' ');
}

export function asArray(string) {
  if (!string) return []; // returns empty array if string === ''
  return string.split(',');
}

export function getQueryParams(...params) {
  return params.map((param) => {
    return getQueryParam(param);
  });
}

//function to remove query params from a URL
function removeURLParameter(url, parameter) {
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '=';
    const params = urlparts[1].split(/[&;]/g);
    //reverse iteration as may be destructive
    for (let i = params.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (params[i].lastIndexOf(prefix, 0) !== -1) {
        params.splice(i, 1);
      }
    }
    url = urlparts[0] + (params.length > 0 ? '?' + params.join('&') : '');
  }
  return url;
}

export function insertQueryParam(key, value) {
  const url = window.location.href;
  //remove any param for the same key
  const cleanUrl = removeURLParameter(url, key);

  //figure out if we need to add the param with a ? or a &
  const queryStart = cleanUrl.indexOf('?') !== -1 ? '&' : '?';

  const newurl = cleanUrl + queryStart + key + '=' + value;
  const newState = { [key]: value };
  window.history.pushState(newState, '', newurl);
  return newurl;
}

export const format = {
  comma(number) {
    if (!number) return '';
    let stringified = number.toString();
    let formatted = '';
    let count = 0; // for tracking every three
    for (let i = stringified.length - 1; i > -1; i--) {
      formatted = stringified.charAt(i) + formatted;
      count++;
      if (count === 3 && i !== 0) {
        formatted = ',' + formatted;
        count = 0;
      }
    }
    return formatted;
  },
};

export function keyUpWrapper(e, cb, ...args) {
  if (e.key === 'Enter') {
    cb(...args);
  }
}

export function env(routeName) {
  const dev = {
    cdc: 'https://wwwdev.cdc.gov',
    nccd: 'https://nccddev.cdc.gov',
  };
  const qa = {
    cdc: dev.cdc,
    nccd: 'https://nccdqa.cdc.gov',
  };
  const prod = {
    cdc: 'https://www.cdc.gov',
    nccd: 'https://nccd.cdc.gov',
  };
  if (window.location.href.match(/qa-.+\.services-dev/)) {
    return qa[routeName];
  } else if (window.location.href.match(/services-dev|localhost/)) {
    return dev[routeName];
  } else {
    return prod[routeName];
  }
}
