import React, { useEffect, useState, useRef } from 'react';
import { getComparisondata } from '../../utils/api';
import { keyUpWrapper, env } from '../../utils/helpers';
import Spinner from '../Spinner';
import { formatTopics, csvExport } from './helpers';
import * as analytics from '../../utils/analytics';
import './style.scss';
import Table from './Table.component.js';

export default function Topics({
  metadata,
  locationIds,
  searchTerm,
  openAll,
  setAllOpen,
  setSearchCount,
  options,
  setLocationIds,
  openedTopics,
  setOpenedTopics,
}) {
  const [topics, setTopics] = useState(undefined);
  function createTopicsMap({ topicId, term }) {
    const topicsMap = new Map();
    metadata.Topic.forEach((topic, index) => {
      let topicIsOpen = false;
      if (!topicId && !term) {
        console.log('topicIsOpen');
        topicIsOpen =
          openAll === undefined && !searchTerm ? index === 0 : openAll;
      }
      if (topicId) {
        if (topicId === topic.Id) {
          // toggle the state based on the old state
          topicIsOpen = !openedTopics.get(topicId);
        } else {
          // keep others the same
          topicIsOpen = openedTopics.get(topic.Id);
        }
      }
      if (term) {
        console.log(term);
        // see if the topic contains any matching search terms
        const pattern = new RegExp(term, 'i');
        topicIsOpen = !!Object.values(topics[topic.Id].questions).find((q) =>
          q.description.match(pattern)
        );
      }
      topicsMap.set(topic.Id, topicIsOpen);
    });
    return topicsMap;
  }
  function countMatches() {
    let count = 0;
    const pattern = new RegExp(searchTerm, 'gi');
    Object.values(topics).forEach(({ questions }) => {
      Object.values(questions).forEach(({ description }) => {
        const match = description.match(pattern);
        if (match) count += match.length;
      });
    });
    return count;
  }
  useEffect(() => {
    getComparisondata({ locationIds }).then((resp) => {
      const formatted = formatTopics({
        topics: metadata.Topic,
        questions: metadata.Question,
        comparisondata: resp,
      });
      setTopics(formatted);
      if (searchTerm) {
        setSearchCount(countMatches());
      }
    });
  }, [locationIds]);

  useEffect(() => {
    if (topics && searchTerm) {
      setSearchCount(countMatches());
      setOpenedTopics(createTopicsMap({ term: searchTerm }));
    }
  }, [locationIds, searchTerm]);

  useEffect(() => {
    console.log('openAlllll');
    setOpenedTopics(createTopicsMap({}));
  }, [openAll]);

  const locations = locationIds.map((id) => {
    return metadata.Location.find((loc) => loc.LocationId === id);
  });
  return !topics ? (
    <Spinner />
  ) : (
    <>
      <div className="row topic-buttons">
        <div
          className="link px-3"
          role="button"
          tabIndex="0"
          onClick={() => setAllOpen(true)}
          onKeyUp={(e) => keyUpWrapper(e, setAllOpen, true)}
        >
          Expand All
        </div>
        <div
          className="link px-3"
          role="button"
          tabIndex="0"
          onClick={() => {
            const open = openAll === false ? 0 : false; // acts as a toggle for the falsy state
            setAllOpen(open);
          }}
          onKeyUp={(e) => keyUpWrapper(e, setAllOpen, false)}
        >
          Collapse All
        </div>
        <div
          onClick={() => {
            analytics.saveAsCSV({ locationIds });
            csvExport({ topics, metadata });
          }}
          role="button"
          className="link topic-csv"
        >
          <span className="x24 fill-p cdc-icon-excel mr-1"></span>Save as CSV
        </div>
      </div>

      {Object.keys(topics).map((topicId, i) => (
        <Table
          key={topicId}
          {...topics[topicId]}
          topicId={topicId}
          locations={locations}
          open={openedTopics.get(topicId)} // this will set the first topic to be open by default
          options={options}
          searchTerm={searchTerm}
          setLocationIds={setLocationIds}
          handleClick={() => {
            setOpenedTopics(createTopicsMap({ topicId }));
          }}
        />
      ))}
    </>
  );
}
